<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey darken-2" elevation="1">
            <v-toolbar-title class="white--text">
              Створення абонентів багатоквартирного будинку
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12">
                  <AddressElementSelect v-model="city_id"
                                        label="Населений пункт" filled
                                        select_type="city"
                                        :auto_fill_when_first="true"
                  />
                </v-col>
                <v-col cols="8">
                  <AddressElementSelect :parent_id="city_id" v-model="street_id"
                                        label="Вулиця(-ки)" filled
                                        select_type="street" use_parent_id
                                        :disabled="!city_id"
                                        @selectChanged="onStreetChange"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="building_number"
                                label="Номер будинку"
                                filled
                                :class="{'req-star': !building_number}"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                hide-details
                                color="grey"
                                @change="onBuildingNumberChange"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.number="flat_number_start"
                                label="Початковий № квартири"
                                filled
                                :class="{'req-star': !flat_number_start}"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                hide-details
                                color="grey"
                                v-decimal
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.number="flat_count"
                                label="К-сть квартир"
                                filled
                                :class="{'req-star': !flat_count}"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                hide-details
                                color="grey"
                                v-decimal
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.number="person_id_start"
                                label="Початковий особовий рахунок"
                                filled
                                :class="{'req-star': !person_id_start}"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                hide-details
                                color="grey"
                                v-decimal
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field v-model="owner_name"
                                label="Прізвище, ім’я та по батькові власника квартири"
                                filled
                                :class="{'req-star': !owner_name}"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                hide-details
                                color="grey"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                      label="Перелік послуг у абонентів" :items="services"
                      v-model="selected_services" multiple
                      hide-details
                      filled
                      dense color="grey" item-color="grey darken-1"
                      clearable
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn depressed text block color="secondary darken-1" class="button-accept"
                         :disabled="!documentReady()"
                         :loading="loading"
                         @click.stop="crud_operation"
                  >
                    Виконати
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import fillAPI from "@/utils/axios/fill_tables";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "CreateFlats",
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll'
    })
  },
  data() {
    return {
      formValid: false,
      city_id: null,
      street_id: null,
      building_number: '',
      flat_number_start: 0,
      flat_count: 0,
      owner_name: '',
      person_id_start: 0,
      building_id: null,
      selected_services: [],
      loading: false
    }
  },
  methods: {
    onStreetChange(payload) {
      if (payload) {
        this.street_id = payload.value || null
        this.get_building()
      } else {
        this.street_id = null
        this.building_id = 0
        this.flat_number_start = 0
      }
    },
    onBuildingNumberChange() {
      this.get_building()
        .then(response => {
          this.building_id = response.building_id
          if (this.building_id) {
            fillAPI.create_flats_get_max_flat_number({ building_id: this.building_id })
              .then(answer => answer.data)
              .then(data => {
                this.flat_number_start = data
              })
          } else {
            this.flat_number_start = 0
          }
        })
        .catch(error => {
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },
    get_building() {
      if (this.street_id && this.building_number) {
        return new Promise((resolve, reject) => {
          fillAPI.create_flats_get_building_by_address({
            street_id: this.street_id,
            building_number: this.building_number
          })
              .then(response => response.data)
              .then(data => {
                resolve(data)
              })
              .catch(err => {
                reject(err.response.data.detail)
              })
        })
      }
    },
    get_max_person_id() {
      fillAPI.create_flats_get_max_person_id()
          .then(response => response.data)
          .then(data => {
            this.person_id_start = +(data || 0)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },
    documentReady() {
      return !(!this.city_id || !this.street_id ||
               !this.flat_count || !this.person_id_start ||
               !this.owner_name || !this.building_number ||
               !this.flat_number_start);

    },
    crud_operation() {
      if (this.documentReady()) {
        const payload = {
          city_id: this.city_id,
          street_id: this.street_id,
          building_number: this.building_number,
          building_id: this.building_id || null,
          flat_number_start: this.flat_number_start,
          flat_count: this.flat_count,
          owner_name: this.owner_name,
          person_id_start: this.person_id_start,
          services: this.selected_services
        }
        this.loading = true

        fillAPI.create_flats(payload)
          .then(response => response.data)
          .then(data => {
            this.$store.commit(ALERT_SHOW, { text: `Успішно створено: ${data} квартир.`  })
            this.building_id = null
            this.building_number = ''
            this.person_id_start = 0
            this.flat_count = 0
            this.flat_number_start = 0
            this.get_max_person_id()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$store.commit(ALERT_SHOW, { text: 'Усі обов’язкові поля мають бути заповнені', color: 'error' })
      }
    }
  },
  created() {
    this.owner_name = 'Невідомий Власник Квартири'
    this.get_max_person_id()
  }
}
</script>

<style scoped>

</style>